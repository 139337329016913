// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-authorisation-js": () => import("./../../../src/pages/docs/authorisation.js" /* webpackChunkName: "component---src-pages-docs-authorisation-js" */),
  "component---src-pages-docs-error-responses-js": () => import("./../../../src/pages/docs/error-responses.js" /* webpackChunkName: "component---src-pages-docs-error-responses-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-docs-rate-limiting-js": () => import("./../../../src/pages/docs/rate-limiting.js" /* webpackChunkName: "component---src-pages-docs-rate-limiting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

